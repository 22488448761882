import { IonGrid, IonList, IonRow, IonCol, IonInput, modalController} from '@ionic/vue';
import IconButton from '@/components/icon-button';
import Event from '@/components/event';
import GridColumn from '@/components/grid-column';
import {LocationCombination, TreeFolder} from '@/models/locationcombination';
import {SortEnum, SortCombination} from '@/models/sortcombination';
import FolderSelectComponent from '@/popups/folderselect';

export default {
    name: 'EventFolder',
    components: {
        IonGrid, IonList, IonRow, IonCol, IonInput, IconButton, Event, GridColumn
    },
    props: {
        labels: {},
        selectedEvent: null,
        aspectTemplate: null,
        outerLimit: null,
        optionItemWidth: null,
        folder: null,
        folders: null,
        parentFolders: null,
        locationCombinations: null,
        sortCombination: SortCombination,
        folderSortCombinationLookup: null,
        pageIsEditing: Boolean,
        level: Number        
    },
    emits: ["onSelect", "onDelete", "onChange", "onFoldersChanged", "onSelectProfile", "onSort", "onDeleteFolder", "onChangeFolder", "onResetParent"],
    inject: ["storageService", "eventFolderService", "luckService", "utilService", "aspectTemplateService", "locationCombinationService"],
    data()
    {
        return   {
            _oldLocationCombinations: {},
            _oldRow: null,
            SortEnum: SortEnum,
            firstLoad: true,
            maxLength: 64,
            aspectTemplateHash: ''
                    //eventsArray: []
        }
    },
    watch: {
        events: function (val, oldVal) { //undo action changes data in row, oldrow needs to be synced as well

            //console.log('eventswatch', val[Object.keys(val)[0]].remark, oldVal[Object.keys(val)[0]].remark);
            this._oldLocationCombinations = {};
            this.storeLocationCombinations(oldVal);
            this.init();
        },
        folder: function (/*val, oldVal*/) { //undo action changes data in row, oldrow needs to be synced as well
            this._oldRow = this.utilService.cloneObject(this.folder);
        }
    },

    mounted() {
        this.firstLoad = true;
        this.storeLocationCombinations();
        this._oldRow = this.utilService.cloneObject(this.folder);
        this.init();
    },
    computed: {
        parentFolderName: function () {
            return this.parentFolder.name;
        },
        events: function () {
            return this.folder.events;
        },
        eventCount: function () {
            return '(' + this.getEventCount() + ')';
        },
        childEventCount: function () {
            var count = this.getChildEventCount(this.folder) - this.getEventCount();
            return (count > 0) ? '(' + count + ')' : '';
        },
        folderHeaderStyle: function () {
            return 'padding-left:' + ((this.level) * 20) + 'px;';
        },
        showRow: function () {
            return this.getEventCount() > 0;
        },
        enrichedEvents: function () {
            var result = [];
            for (var eventDate in this.folder.events) {
                var profileEvent = this.folder.events[eventDate];
                var isSelected = this.isSelectedEvent(profileEvent);
                var item = {
                    eventDate: eventDate,
                    profileEvent: profileEvent,
                    isSelected: isSelected
                };
                if (this.firstLoad && isSelected && result.length > 0) {
                    result.unshift(item);
                } else {
                    result.push(item);
                }
            }
//            this.firstLoad = false;
            return result;
        },
        surfaceDeepMode: function () {
            return this.aspectTemplate && this.aspectTemplate.propertiesVm && this.aspectTemplate.propertiesVm.surfaceDeepMode;
        },
        rowClass: function () {
            var result = 'row border-default';
            if (this.hasSelectedEvent) {
                result += ' selected';
            }
            if (!(this.eventFolderService.isEditing(this.folder))) {
                result += " pointer";
            }
            return result;
        },
        isExpanded: function () {
            return this.eventFolderService.isExpanded(this.folder);// && (!this.eventFolderService.activeLookupCode || this.folder.lookupCode.length <= this.eventFolderService.activeLookupCode.length);
        },
        isEventsExpanded: function () {
            return this.eventFolderService.isEventsExpanded(this.folder);// && (!this.eventFolderService.activeLookupCode || this.eventFolderService.activeLookupCode == this.folder.lookupCode);
        },
        isEditing: function () {
            return this.eventFolderService.isEditing(this.folder) && this.pageIsEditing;
        },
        isFolderEditing: function () {
            return this.eventFolderService.isFolderEditing(this.folder) && this.pageIsEditing;
        },
        visibleChildren: function () {
            return this.folder.children.filter(x => !x.isHidden).map(folder => {
                return {
                    folder: folder,
                    sortCombination: this.folderSortCombinationLookup[folder.guid]};
            });
        },
        hasSelectedEvent: function () {
            return this.folder.hasSelectedEvent;
        },
        isUncategorizedFolder: function () {
            return this.eventFolderService.isUncategorizedFolder(this.folder);
        },
        showBackButton: function () {
            return this.folder.lookupCode == this.eventFolderService.activeLookupCode;
        },
    },
    methods: {
        init: function () {

        },
        getEventCount: function () {
            return Object.keys(this.folder.events).length;
        },
        getChildEventCount: function (folder) {
            var result = Object.keys(folder.events).length;
            if (folder.children) {
                folder.children.forEach(child => {
                    result += this.getChildEventCount(child);
                });
            }
            return result;
        },
        toggle: function () {
            this.eventFolderService.toggle(this.folder);
        },

        isSelectedEvent: function (profileEvent) {
            return profileEvent == this.selectedEvent;
        },
        storeLocationCombinations: function () {
            Object.keys(this.events).forEach(eventDate => {
//                console.log('events[row.eventDate]', this.events[eventDate]);
                var locationCombinationGuid = this.events[eventDate].locationCombinationGuid;
                if (locationCombinationGuid && !(locationCombinationGuid in this._oldLocationCombinations)) {
                    this._oldLocationCombinations[locationCombinationGuid] = new LocationCombination();
                    var locationCombination = this.locationCombinations.find(x => x.guid == locationCombinationGuid);
                    this._oldLocationCombinations[locationCombinationGuid].copy(locationCombination);
                }

            });
        },
        getLuckFigure: function (event, callback) {
            var eventDate = event.date;
            if (this.aspectTemplate && !this.aspectTemplateHash) {
                this.aspectTemplateHash = this.aspectTemplate.id + '_' + (this.aspectTemplate.changedOn ? this.aspectTemplate.changedOn : '-');
            }

            var locationCombination = this.locationCombinations.find(x => x.guid == event.locationCombinationGuid);
            var locationCombinationHash = this.locationCombinationService.getLocationCombinationHash(locationCombination);

            if (!event.luckFigure
                    || (event.luckFigure.aspectTemplateHash != this.aspectTemplateHash)                    
                    || (event.luckFigure.locationCombinationHash != locationCombinationHash)
                    ) {
                //console.log('getLuckFigure', event, locationCombination);
                this.luckService.getLuckFigures(this.aspectTemplate, this.outerLimit, [], eventDate, false, false,
                        locationCombination.birthLocation, locationCombination.eventLocation, locationCombination.birthDate
                        ).then(x => {
                    var luckFigure = x.luckFigures[0];
                    luckFigure.aspectTemplateHash = this.aspectTemplateHash;
                    luckFigure.surfaceDeepMode = this.surfaceDeepMode;
                    luckFigure.locationCombinationHash = locationCombinationHash;
                    callback(luckFigure);
                });
            }

        },
        onChangeEvent: function (eventDate) {
            console.log('change');
            var event = this.events[eventDate];
            var locationCombinationGuid = event.locationCombinationGuid;
            var locationCombination = this.locationCombinations.find(x => x.guid == locationCombinationGuid);
            if (JSON.stringify(event) != JSON.stringify(this._oldLocationCombinations[locationCombinationGuid].events[eventDate])) {
                this.$emit('onChange', this._oldLocationCombinations[locationCombinationGuid]);
                this._oldLocationCombinations[locationCombinationGuid].copy(locationCombination);
            }
        },

        doChange: function (oldLocationCombination) {
            this.$emit('onChange', oldLocationCombination);
        },
        select: function (event) {
            var locationCombinationGuid = event.locationCombinationGuid;
            var locationCombination = this.locationCombinations.find(x => x.guid == locationCombinationGuid);
            this.$emit('onSelect', locationCombination, event.date);
        },
        doSelect: function (locationCombination, date) {
            this.$emit('onSelect', locationCombination, date);
        },
        selectProfile: function (event) {
            var locationCombinationGuid = event.locationCombinationGuid;
            var locationCombination = this.locationCombinations.find(x => x.guid == locationCombinationGuid);
            this.$emit('onSelectProfile', locationCombination, event);
        },
        doSelectProfile: function (locationCombination, event) {
            this.$emit('onSelectProfile', locationCombination, event);
        },

        deleteFolder: function (folder) {
            this.$emit('onDeleteFolder', folder);
        },
        doDelete: function (key) {
            var event = this.events[key];
            var locationCombinationGuid = event.locationCombinationGuid;
            var locationCombination = this.locationCombinations.find(x => x.guid == locationCombinationGuid);
            //console.log('onDelete', locationCombination, key);
            this.deleteEvent(locationCombination, key);
        },
        deleteEvent: function (locationCombination, key) {
            this.$emit('onDelete', locationCombination, key);
        },
        foldersChanged: function (key) {
            this.$emit('onFoldersChanged', key);
        },
        checkFolderChanged: function () {
            console.log('change', this.folder.name, this._oldRow.name);
            if (JSON.stringify(this.folder) != JSON.stringify(this._oldRow)) {
                this.changeFolder(this._oldRow);
                this._oldRow = this.utilService.cloneObject(this.folder);
            }
        },
        addFolder: function () {
            var folder = new TreeFolder();
            folder.isEditing = true;
            //folder.name = this.labels.strNew;
            this.folder.children.unshift(folder);
        },
        changeFolder: function (oldFolder) {
            this.$emit('onChangeFolder', oldFolder);
        },
        sort: function (eventSortEnum) {
            this.firstLoad = false;
            this.$emit('onSort', this.folder, eventSortEnum);
        },
        doSort: function (folder, eventSortEnum) {
            this.$emit('onSort', folder, eventSortEnum);
        },
        blurFolder: function () {
            this.checkFolderChanged();
            this.$emit('onFoldersChanged');
        },
        expand: function () {
            this.eventFolderService.expand(this.folder, false, false);
        },
        ascend: function (index) {
            var folder = index < this.parentFolders.length - 1 ? this.parentFolders[index + 1] : this.folder;
            this.eventFolderService.contract(folder, true);
            this.$emit('onFoldersChanged');
        },
        descend: function () {
            this.eventFolderService.expand(this.folder, false, true);
            this.$emit('onFoldersChanged');
        },
        contract: function () {
            this.eventFolderService.contract(this.folder, false);
        },
        readOnly: function () {
            this.eventFolderService.setReadOnly(this.folder);
        },
        edit: function () {
            this.eventFolderService.edit(this.folder);
        },
        setParent: function () {
            //console.log('this.labels.strSetParent',this.labels.strSetParent);            
            modalController.create({
                showBackdrop: true,
                cssClass: 'transparent-modal',
                component: FolderSelectComponent,
                componentProps: {
                    folderGuid: this.folder.parentGuid,
                    readOnly: true,
                    title: this.labels.setParent,
                    guidToExclude: this.folder.guid
                }
            }).then(popup => {
                popup.present();
                popup.onDidDismiss().then(x => {
                    if (x.data) {
                        var oldParentGuid = this.folder.parentGuid;
                        this.folder.parentGuid = x.data.guid;
                        this.resetParent(this.folder, oldParentGuid);
                        this.checkFolderChanged();
                    }
                });
            });
        },

        resetParent: function (folder, oldParentGuid) {
            this.$emit('onResetParent', folder, oldParentGuid);
        }
    },

}