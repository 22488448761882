<ion-page>   
    <page-header :loadTrigger='loadTrigger' :showUndo="showUndo" :undoButtonText="undoButtonText" 
                 :beforeShowHistoryCallback="beforeShowHistoryCallback" :afterImportProfiles="load"                 
                 :afterShowHistoryCallback="afterShowHistoryCallback"
                 :showHistoryMenu="storeUserProfilesInDb" 
                 @onUndo="undo" 
                 ></page-header>
    <ion-content>                        
        <ion-row class="nomargin" color='tertiary'>

            <ion-col class="nomargin">
                <ion-buttons class="nomargin buttons" >                          
                    <div class="fullWidth inputdiv">                        
                        <ion-input class="search" type="text" v-model="searchString" @ionChange="onSearch" debounce="500" :placeholder="labels.search" />
                    </div>
                    <ion-button class="button btn width60"  @click="add" v-if="isEditing && showAdd">
                        <ion-icon  name="plus" size="large" class="panelicon"></ion-icon>                
                    </ion-button>                                    
                    <ion-button class="button btn width60"  @click="expandAll" v-if="!isExpanded">
                        <ion-icon  name="expand" size="large" class="panelicon"></ion-icon>                                                          
                    </ion-button>                                    
                    <ion-button class="button btn width60"  @click="contractAll" v-if="isExpanded">
                        <ion-icon  name="contract" size="large" class="panelicon"></ion-icon>                                                          
                    </ion-button>                                    

                    <ion-button class="button btn width60"  @click="onLock" v-if="isEditing" :title="labels.readOnly">
                        <ion-icon  name="lockclosed" size="large" class="panelicon"></ion-icon>                
                    </ion-button>                                    
                    <ion-button class="button btn width60"   @click="onEdit" v-if="!isEditing">
                        <ion-icon  name="create" size="large" class="minwidth50  panelicon" ></ion-icon>
                    </ion-button>                                    
                </ion-buttons>
            </ion-col>
        </ion-row>        
        <ion-row v-for="(item,index) in enrichedFolders" :key="index">
            <ion-col>
                <event-folder :labels="labels"                                           
                              :optionItemWidth="optionItemWidth" :outerLimit="outerLimit" 
                              :folder="item.folder"              
                              :folders="folders"
                              :aspectTemplate="aspectTemplate"
                              :selectedEvent="selectedEvent"             
                              :pageIsEditing="isEditing"
                              :locationCombinations="locationCombinations"                         
                              :sortCombination="item.sortCombination"                                  
                              :folderSortCombinationLookup="folderSortCombinationLookup"
                              :parentFolders="parentFolders"                                                            
                              :level="0"                                                          
                              @onSelect="select" @onChange="addToHistory" @onFoldersChanged="foldersChanged"                                  
                              @onDeleteFolder="deleteFolder" @onChangeFolder="changeFolder"
                              @onResetParent="resetParentFolder"
                              @onDelete="deleteEvent"  @onSelectProfile="selectProfile" @onSort="sort"                                  
                              />                                                
            </ion-col>           
        </ion-row>        
    </ion-content>
</ion-page>