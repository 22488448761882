<ion-row :style="folderHeaderStyle" :class="rowClass" >        
    <ion-col size="8">
        <div v-if="parentFolders && showBackButton && !folder.isNew" class="breadcrumbs">
            <div v-for="(parentFolder, index) in parentFolders" :key="index" @click="ascend(index)" class="float-left">
                {{parentFolder.name}} /
            </div>                            
            <div class="float-left" v-html="folder.name"></div>                                
            <div class="clear"></div>
        </div>            
        <icon-button class="button ion-float-left landscape" :title="labels.expand"  @click="expand" icon="openfolder" v-if="!isExpanded"></icon-button>
        <icon-button class="button ion-float-left landscape" :title="labels.contract"  @click="contract" icon="closefolder" v-if="isExpanded && !showBackButton"></icon-button>                         
        <div class="foldername" v-if="!isFolderEditing && !showBackButton" @click="descend" >{{folder.name}}<span class="childcount" v-html="eventCount"></span><span class="childcount" v-html="childEventCount"></span></div>                            
        <input v-if="isFolderEditing" type="text" class="form-control ion-input float-left" :maxlength="maxLength" v-model="folder.name" :placeholder="labels.folder" v-on:blur="blurFolder"/>            
    </ion-col>           
    <ion-col size="4" v-if='isEventsExpanded && pageIsEditing'>                                                              
        <icon-button v-if="!isUncategorizedFolder" class="button ion-float-right" :title="labels.add"  @click="addFolder" icon="add"></icon-button>
        <icon-button v-if="!isUncategorizedFolder" class="button ion-float-right" :title="labels.delete"  @click="deleteFolder(folder)" icon="trash"></icon-button>
        <icon-button v-if="!isEditing" class="button ion-float-right" :title="labels.edit" @click="edit" icon="create"></icon-button>
        <icon-button v-if="isEditing" class="button ion-float-right" :title="labels.readOnly" @click="readOnly" icon="eye"></icon-button>                                
        <icon-button v-if="!isUncategorizedFolder && isFolderEditing" class="button ion-float-right" :title="labels.setParent"  @click="setParent" icon="setparent"></icon-button>
    </ion-col>                        
</ion-row>                        
<ion-row v-if="isEventsExpanded">
    <ion-col>
        <ion-list lines="none">
            <ion-row class="gridheader" v-if="showRow">
                <ion-col size-md="1.5" size-xs="4">
                    <grid-column :displayName="labels.fileName" :sortEnum="SortEnum.Reference" :sortCombination="sortCombination" @onClick="sort"></grid-column>
                </ion-col>
                <ion-col size-md="1.5" size-xs="4">
                    <grid-column :displayName="labels.date" :sortEnum="SortEnum.Date"  :sortCombination="sortCombination"  @onClick="sort"></grid-column>
                </ion-col>
                <ion-col size-md="1.5" size-xs="4">
                    <grid-column :displayName="labels.profile" :sortEnum="SortEnum.Profile"  :sortCombination="sortCombination"  @onClick="sort"></grid-column>
                </ion-col>
                <ion-col size-md="1.5" class="hidden-xs">
                    
                </ion-col>
            </ion-row>
            <ion-row>                
                <ion-col size-md="6" size-xs="12" v-for="row in enrichedEvents">            
                    <event :labels="labels" :event="row.profileEvent" :eventDate="row.eventDate" 
                           :selected="row.isSelected" :folders="folders" :optionItemWidth="optionItemWidth" 
                           :outerLimit="outerLimit" :aspectTemplate="aspectTemplate" :eventFolderView="true"
                           :locationCombinationName="row.profileEvent.locationCombinationName"
                           @onGetLuckFigure="getLuckFigure" @onSelect="select" @onChange="onChangeEvent" @onFoldersChanged="foldersChanged"                               
                           @onDelete="doDelete" @onSelectProfile="selectProfile"></event>            
                </ion-col>        
            </ion-row>
        </ion-list>

    </ion-col>        
</ion-row>    
<event-folder 
    v-for="child in visibleChildren" 
    v-if="isExpanded"
    :labels="labels" :level="level + 1"
    :optionItemWidth="optionItemWidth" :outerLimit="outerLimit" 
    :folder="child.folder"                                         
    :folders="folders"
    :aspectTemplate="aspectTemplate"
    :selectedEvent="selectedEvent"                                                                                  
    :pageIsEditing="pageIsEditing"
    :locationCombinations="locationCombinations"
    :folderSortCombinationLookup="folderSortCombinationLookup"
    :sortCombination="child.sortCombination"    
    @onSelect="doSelect" @onChange="doChange" @onFoldersChanged="foldersChanged"                          
    @onDeleteFolder="deleteFolder" @onChangeFolder="changeFolder"
    @onResetParent="resetParent"
    @onDelete="deleteEvent"  @onSelectProfile="doSelectProfile" @onSort="doSort"                          
    />                                                
