import { IonPage, IonContent, IonGrid, IonRow, IonCol, IonInput, IonButtons, IonButton, IonIcon } from '@ionic/vue';
import PageHeader from '@/components/page-header';
import EventFolder from '@/events-page/eventfolder';
import IconButton from '@/components/icon-button';
import {StorageEnum, /*SortEnum, */} from '@/constants/enums';
import router from '@/router';
import {EnumResourceStrings} from '@/constants/enumresourcestrings';
import {LocationCombination, TreeFolder} from '@/models/locationcombination';
import {SortCombination, SortDirection, SortEnum} from '@/models/sortcombination';
import Constants from '@/constants/constants';
import openfolder from '/public/assets/icon/folder-closed.svg';
import closefolder from '/public/assets/icon/folder-open.svg';
import { add, lockClosed as lockclosed, create, addCircle as plus, expand, contract,
        trash, lockClosed as eye, albumsOutline as setparent,
        chevronBackOutline as chevronback, thumbsUp as thumbsup, thumbsDown as thumbsdown, folderOutline as folder, save,
        documentTextOutline as details
} from 'ionicons/icons';
import { addIcons } from 'ionicons';
//arrow-down-outline

const HistoryTypeEnum = {
    LocationCombination: 1,
    Folders: 2
};



export default {

    components: {
        IonPage, PageHeader, IonContent, IonGrid, IonRow, IonCol, IonInput, EventFolder, IconButton, IonButtons, IonButton, IonIcon
    },
    setup() {
        addIcons({
            add,
            lockclosed,
            create,
            plus,
            expand,
            contract,
            trash, openfolder, closefolder, eye, setparent, //eventfolder icons,
            folder,
            save,
            thumbsup,
            thumbsdown,
            chevronback,
            details
        });
    },
    data() {
        return{
            firstMount: true,
            labels: {},
            optionItemWidth: null,
            aspectTemplate: null,
            aspectTemplateHash: '',
            outerLimit: null,
            selectedLocationCombination: null,
            _userProfileHash: null,
            selectedEvent: null,
            history: [],
            parentFolders: [],
            locationCombinations: null,
            maxLength: 64,
            foldersArray: [],
            folderSortCombinationLookup: {},
            searchString: null,
            reloading: false,
            showAdd: true,
            isEditing: false,
            drillToSelectedEvent: false,
            isExpanded: false,
            storeUserProfilesInDb: true,
            activeLookupCode: null,
            folders: [],
            surfaceDeepMode: false,
            loadTrigger: 0,
            lookupCodeLength: 0
        }
    },
    inject: ["storageService", "languageService", "eventService", "aspectTemplateService", "locationCombinationService",
        "folderService", "eventFolderService", "licenseService", "exportService", "userProfileService", "utilService", "luckService"],
    computed: {
        showUndo: function () {
            return this.history.length > 0;
        },
        undoButtonText: function () {
            var result = '';
            if (this.labels.undo) {
                result = this.utilService.formatString(this.labels.undo, [this.history.length]);
            }
            return result;
        },
        enrichedFolders: function () {
            return this.visibleFolders.map(folder => {
                return {
                    folder: folder,
                    sortCombination: this.folderSortCombinationLookup[folder.guid]
                }
            });
        },
        visibleFolders: function () {
            var parents = [];
            var rootFolder = new TreeFolder();
            rootFolder.name = this.labels.home;
            rootFolder.lookupCode = '';
            parents.push(rootFolder);
            var treeFolders = this.foldersArray.filter(x => !x.isHidden);
            while (this.eventFolderService.activeLookupCode
                    && treeFolders.length == 1
                    && treeFolders[0].lookupCode.length < this.eventFolderService.activeLookupCode.length) {
                //remove until we're at the selected folder                                
                parents.push(treeFolders[0]);
                treeFolders = treeFolders[0].children.filter(x => !x.isHidden);
            }
            this.parentFolders = parents;
            this.showAdd = !this.eventFolderService.activeLookupCode;
            return treeFolders;
        },

    },
    mounted() {
        this.init();
    },

    ionViewDidEnter() {
        this.searchString = null;
        this.eventFolderService.activeLookupCode = null;

        if (this.firstMount) {
            this.firstMount = false;
        } else {
            this.init();
        }
    },
    ionViewWillLeave: function () {
        if (!this.redirecting && !this.storageService.isLoggingOut) {
            return this.save();
        }
    },
    methods: {
        init: function () {
            this.loadTrigger++;
            this.languageService.getText(EnumResourceStrings.SelectFolder).then(x => this.labels.selectFolder = x);
            this.languageService.getText(EnumResourceStrings.Folder).then(x => this.labels.folder = x);
            this.languageService.getText(EnumResourceStrings.Events).then(x => this.labels.events = x);
            this.languageService.getText(EnumResourceStrings.FileName).then(x => this.labels.fileName = x);
            this.languageService.getText(EnumResourceStrings.Delete).then(x => this.labels.delete = x);
            this.languageService.getText(EnumResourceStrings.Add).then(x => this.labels.add = x);
            this.languageService.getText(EnumResourceStrings.Edit).then(x => this.labels.edit = x);
            this.languageService.getText(EnumResourceStrings.Undo).then(x => this.labels.undo = x);
            this.languageService.getText(EnumResourceStrings.Expand).then(x => this.labels.expand = x);
            this.languageService.getText(EnumResourceStrings.Contract).then(x => this.labels.contract = x);
            this.languageService.getText(EnumResourceStrings.ReadOnly).then(x => this.labels.readOnly = x);
            this.languageService.getText(EnumResourceStrings.Folder).then(x => this.labels.folder = x);
            this.languageService.getText(EnumResourceStrings.Date).then(x => this.labels.date = x);
            this.languageService.getText(EnumResourceStrings.Profile).then(x => this.labels.profile = x);
            this.languageService.getText(EnumResourceStrings.SelectParentFolder).then(x => this.labels.setParent = x);
            this.languageService.getText(EnumResourceStrings.New).then(x => this.labels.new = x);
            this.languageService.getText(EnumResourceStrings.Home).then(x => this.labels.home = x);
            this.languageService.getText(EnumResourceStrings.Search).then(x => this.labels.search = x);
            var promises = [];
            promises.push(this.licenseService.getLicense().then(x => {
                this.outerLimit = x.license.propertiesVm.aspectSecondaryDegreeAsDouble;
            }));
            promises.push(this.aspectTemplateService.getCurrentAspectTemplate().then(aspectTemplate => {
                //console.log('aspectTemplate', aspectTemplate);
                this.aspectTemplate = aspectTemplate;
                this.aspectTemplateHash = this.aspectTemplate.id + '_' + (this.aspectTemplate.changedOn ? this.aspectTemplate.changedOn : '-');
                this.surfaceDeepMode = this.aspectTemplate.propertiesVm.surfaceDeepMode;
            }));
            promises.push(this.locationCombinationService.getSelectedEvent().then(x => {
                this.selectedEvent = x;
                if (this.selectedEvent) {
                    promises.push(this.folderService.getFolderDictionary().then(folders => {
                        var folder = folders[this.selectedEvent.folderGuid];
                        if (folder) {
                            console.log('folder lookup', folder.lookupCode);
                            this.activeLookupCode = folder.lookupCode;
                        }
                    }));
                }
            }));
            //
            promises.push(promises.push(this.locationCombinationService.getSelectedLocationCombination().then(x => {
                this.selectedLocationCombination = x;
            })));
            promises.push(this.doLoadFolders());
            promises.push(this.storageService.get(StorageEnum.DrillToSelectedEvent).then(x => {
                this.drillToSelectedEvent = x;
//                console.log('this.drillToSelectedEvent', this.drillToSelectedEvent);
                if (x) {
                    this.storageService.set(StorageEnum.DrillToSelectedEvent, null);
                }
            }));
            promises.push(this.exportService.getExportJson().then(json => {
                this._userProfileHash = this.utilService.getObjectHash(json);
            }));
            Promise.all(promises).then(() => {
                this.load();
            });
        },

        load: function () {
            this.eventFolderService.clearState();
            //only do this when redirecting from events or dashboard
            if (this.drillToSelectedEvent) {
                this.eventFolderService.activeLookupCode = this.activeLookupCode;
            }
            this.loadEvents();
            this.ensureOptionItemWidth();
        },
        setOptionsWidth: function () {
            if (!this.optionItemWidth) {
                var width = this.$el.offsetWidth;
                if (width > 500) {
                    width /= 2;
                }
                if (width) {
                    this.optionItemWidth = width - 50;
                }
            }
        },
        ensureOptionItemWidth: function () {
            var counter = 1;
            var interval = setInterval(() => {
                console.log('going for width', interval);
                this.setOptionsWidth();
                if (this.optionItemWidth > 0 || counter++ > 50) {
                    clearInterval(interval);
                }
            }, 100);
        },
        doDeleteFolder: function (folders, folder) {
            var index = -1;
            for (var i = 0; i < folders.length; i++) {
                if (folders[i].guid == folder.guid) {
                    folders.splice(i, 1);
                    index = i;
                } else {
                    index = this.doDeleteFolder(folders[i].children, folder);
                }
                if (index > -1) {
                    break;
                }
            }
            return index;
        },
        deleteFolder: function (folder) {
            this.doDeleteFolder(this.foldersArray, folder);
            this.addFolderToHistory(this.utilService.cloneObject(folder));
            this.reloadFolders();
        },
        reloadFolders: function () {
            this.saveFolders().then(() => {
                this.loadFolders();
            });
        },
        loadFolders: function () {
            this.doLoadFolders().then(() => {
                this.loadEventFolders();
            });
        },
        loadEvents: function () {
            return this.locationCombinationService.getLocationCombinations().then(locationCombinations => {
                this.locationCombinations = locationCombinations
                return this.loadEventFolders();
            });
        },

        loadEventFolders: function () {
            return this.eventService.getEventFolders(this.locationCombinations, this.folderSortCombinationLookup, this.searchString).then(eventFolders => {
                this.eventFolderService.syncState(eventFolders);
                this.lookupCodeLength = this.eventFolderService.activeLookupCode ? this.eventFolderService.activeLookupCode.length : 0;
                if (!this.reloading) {
                    eventFolders.forEach(folder => {
                        this.expandIfNeeded(folder, [], true);
                    });
                }
                this.foldersArray = eventFolders;

                this.reloading = false;
            });
        },

        expandIfNeeded: function (folder, parentFolders, seekSelected) {

            folder.hasSelectedEvent = seekSelected && this.hasSelectedEvent(folder);
            if (folder.hasSelectedEvent) {
                seekSelected = false;
            }

            if (folder.hasSelectedEvent || this.selectedEvent == null || this.searchString) {
                if (folder.hasSelectedEvent || this.selectedEvent != null || this.searchString || folder.lookupCode.length <= this.lookupCodeLength + 2) {
                    //        console.log('expand', lookupCodeLength + 2, folder.lookupCode.length);
                    this.eventFolderService.expand(folder);
                } else {
                    this.eventFolderService.contract(folder);
                }
                if (folder.hasSelectedEvent) {
                    parentFolders.forEach(parent => {
                        this.eventFolderService.expand(parent, true, false);
                    });
                }
            }
            if (folder.children) {
                parentFolders.push(folder);
                folder.children.forEach(child => {
                    seekSelected = this.expandIfNeeded(child, parentFolders, seekSelected);
                });
            }
            return seekSelected;
        },
        hasSelectedEvent: function (folder) {
            var result = false;
            if (this.selectedEvent) {
                for (var key in folder.events) {
                    var profileEvent = folder.events[key];
                    if (profileEvent == this.selectedEvent) {
                        result = true;
                        break;
                    }
                }
            }
            return result;
        },
        expandAll: function () {
            console.log('expandAll');
            this.eventFolderService.expandAll();
            this.doLoadFolders().then(() => {
                this.reloading = true;
                this.loadEventFolders().then(() => {
                    this.isExpanded = true;
                });
            });
        },
        contractAll: function () {
            console.log('contractAll');
            this.eventFolderService.contractAll();
            this.doLoadFolders().then(() => {
                this.reloading = true;
                this.loadEventFolders().then(() => {
                    this.isExpanded = false;
                });
            });
        },

        doLoadFolders: function () {
            return this.folderService.getFolderDictionary().then(folders => {
                for (var guid in folders) {
                    var folder = folders[guid];
                    var sortCombination = new SortCombination(SortEnum.Date);
                    sortCombination.sortDirection = SortDirection.Desc;
                    this.folderSortCombinationLookup[folder.guid] = sortCombination;
                }
                this.folders = folders;
                return true;
            });
        },

        resetParentFolder: function (folder, oldParentGuid) {

            var newParent = folder.parentGuid ? this.folderService.findFolder(this.visibleFolders, folder.parentGuid) : null;
            var oldParent = oldParentGuid ? this.folderService.findFolder(this.visibleFolders, oldParentGuid) : null;
            if (oldParent) {
                oldParent.children = oldParent.children.filter(x => x.guid != folder.guid);
            } else {
                this.foldersArray = this.foldersArray.filter(x => x.guid != folder.guid);
            }
            if (newParent) {
                newParent.children.push(folder);
            } else {
                this.foldersArray.push(folder);
            }
        },
        changeFolder: function (folderHistory) {
            this.addFolderToHistory(folderHistory);
            this.reloadFolders();
        },
        foldersChanged: function () {
            this.loadFolders();
        },
        select: function (locationCombination/*: LocationCombination*/, eventDate) {
            this.selectedLocationCombination = locationCombination;
            if (eventDate) {
                var key = this.utilService.formatISODate(eventDate);
                this.selectedEvent = locationCombination.events[key];
            }
            var promises = [];

            promises.push(this.storageService.setDate(StorageEnum.Event, eventDate));

            Promise.all(promises).then(() => {
                this.redirect('/tabs/dashboard');
            });
        },
        selectProfile: function (locationCombination, event) {
            this.selectedLocationCombination = locationCombination;
            this.selectedEvent = event;
            var promises = [];
            promises.push(this.storageService.setDate(StorageEnum.Event, null));
            Promise.all(promises).then(() => {
                this.redirect('/tabs/profiles');
            });
        },
        onLock: function () {
            this.isEditing = false
        },
        onEdit: function () {
            this.isEditing = true;
        },
        add: function () {
            var folder = new TreeFolder();
            folder.isEditing = true;
            folder.isNew = true;
            this.foldersArray.unshift(folder);
        },
        edit: function (folder) {
            this.eventFolderService.edit(folder);
        },
        saveFolders: function () {
            var folders = this.foldersArray.filter(x => {
                return x.guid != Constants.GUID_NULL;//uncategorized 
            });
            console.log('saveFolders', folders);
            return this.folderService.setFolderTree(folders);
        },
        redirect: function (route) {
            this.redirecting = true;
            this.save().then(() => {
                //this.$router.push({name: 'dashboard', params: {selectedLocationCombination: this.selectedLocationCombination}});
                router.replace(route);
            });
        },
        removeItemData: function (locationCombination) {
            if (locationCombination) {
                delete locationCombination.isSelected;
                if (locationCombination.events) {
                    Object.keys(locationCombination.events).forEach(eventDate => {
                        delete locationCombination.events[eventDate].locationCombinationName;
                        delete locationCombination.events[eventDate].locationCombinationGuid;
                    });
                }
            }
        },
        beforeShowHistoryCallback: function () {
            this.history = [];
            //this.save();
        },
        afterShowHistoryCallback: function () {
            return this.exportService.getExportJson().then(json => {
                this._userProfileHash = this.utilService.getObjectHash(json);
            })
        },
        save: function () {
            var promises = [];
            if (this.locationCombinations) {
                var locationCombinations = this.locationCombinations.filter(y => {
                    return this.locationCombinationService.isComplete(y);
                }).map(x => {
                    this.removeItemData(x);
                    return x;
                }).sort((a, b) => {
                    return a.name > b.name ? 1 : -1; //needed to avoid creating unneeded new records in tblUserProfile
                });

                if (this.selectedLocationCombination) {
                    this.removeItemData(this.selectedLocationCombination);
                    promises.push(this.locationCombinationService.setSelectedEvent(this.selectedEvent));
                    promises.push(this.locationCombinationService.setSelectedLocationCombination(this.selectedLocationCombination));
                }
                promises.push(this.locationCombinationService.setLocationCombinations(locationCombinations));
            }
            promises.push(this.saveFolders());
            promises.push(this.exportService.getExportJson().then(json => {
                var newHash = this.utilService.getObjectHash(json);
                if (this._userProfileHash != newHash) {
                    return this.userProfileService.update(json).then(() => {
                        this._userProfileHash = newHash;
                    });
                }
            }));
            this.history = [];
            return Promise.all(promises);
        },
        cleanFolderEvents(historyFolder) {
            historyFolder.events = {};
            historyFolder.children.forEach(child => {
                this.cleanFolderEvents(child);
            });
        },
        addFolderToHistory: function (historyFolder) {
            //console.log('historyFolder.guid',historyFolder, historyFolder.folder.guid);
            this.cleanFolderEvents(historyFolder);
            this.history.push({type: HistoryTypeEnum.Folders,
                guid: historyFolder.folder.guid,
                item: historyFolder
            });
        },
        addToHistory: function (locationCombination) {
            var item = new LocationCombination();
            item.copy(locationCombination);
            this.history.push({type: HistoryTypeEnum.LocationCombination,
                guid: item.guid,
                item: item
            });
        },

        undo: function () {
            var length = this.history.length;
            if (length > 0) {
                var historyItem = this.history.pop();
                var guid = historyItem.guid;
                var index = -1;
                var i = 0;
                var count = 0;
                if (historyItem.type == HistoryTypeEnum.LocationCombination) {
                    count = this.locationCombinations.length;
                    for (i = 0; i < count; i++) {
                        if (this.locationCombinations[i].guid == guid) {
                            index = i;
                            break;
                        }
                    }
                    if (index > -1) {
                        this.locationCombinations[index] = historyItem.item;
                    } else {
                        this.locationCombinations.push(historyItem.item);
                    }
                    this.loadEventFolders();
                } else if (historyItem.type == HistoryTypeEnum.Folders) {

                    var parentFolder = null;
                    var treeFolder = this.restoreTreeFolder(historyItem.item);
                    if (treeFolder.parentGuid) {
                        parentFolder = this.folderService.findFolder(this.foldersArray, treeFolder.parentGuid);
                    }
                    var currentFolder = this.folderService.findFolder(this.foldersArray, treeFolder.guid);
                    console.log('undo folder change', treeFolder, parentFolder);
                    var folders = parentFolder ? parentFolder.children : this.foldersArray;
                    count = folders.length;
                    for (i = 0; i < count; i++) {
                        if (folders[i].guid == treeFolder.guid) {
                            index = i;
                            break;
                        }
                    }
                    if (index > -1) {
                        folders[index] = this.restoreTreeFolder(treeFolder);
                    } else {
                        if (currentFolder) {
                            this.resetParentFolder(treeFolder, currentFolder.parentGuid);
                        } else {
                            folders.push(treeFolder);
                        }
                    }
                    this.reloadFolders();
                }
            }
        },

        restoreTreeFolder: function (staleFolder) {
            var treeFolder = new TreeFolder(staleFolder.folder);
            staleFolder.children.forEach(child => {
                treeFolder.children.push(this.restoreTreeFolder(child));
            });
            return treeFolder;
        },

        deleteEvent: function (locationCombination, key) {
            this.addToHistory(locationCombination);
            var index = key.indexOf('_');
            var eventDate = key.substring(0, index);
            console.log('deleteEvent', locationCombination, locationCombination.events[eventDate]);
            delete locationCombination.events[eventDate];
            this.loadEventFolders();
        },
        sort: function (folder, eventSortEnum) {
            var eventSort = this.folderSortCombinationLookup[folder.guid];
            if (!eventSort) {
                eventSort = new SortCombination(eventSortEnum);
            } else {
                if (eventSort.sortEnum == eventSortEnum) {
                    eventSort.sortDirection = eventSort.sortDirection == SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc;
                } else {
                    eventSort.sortEnum = eventSortEnum;
                    eventSort.sortDirection = SortDirection.Asc;
                }
            }
            this.folderSortCombinationLookup[folder.guid] = eventSort;
            this.reloading = true;
            this.loadEventFolders();
        },
        onSearch: function () {
            this.loadEventFolders();
        }
    }
}
